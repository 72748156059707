.errorBox {
  border: 1px solid #e02d38;
}

.errorBackground {
  background: #fef4f4;
}

.errorText {
  color: #e02d38;
  font-size: 12px;
  padding-top: 4px;
}
