.dropdownButton {
  height: 38px;
  background: #ffffff;
  border: 1px solid #c9cbcf;
  box-sizing: border-box;
  color: #2f3031;
  font-size: 15px;
  line-height: 22px;
}

.dropdownButton:hover {
  background: #f5f5f5;
}

.dropdownButton.dropdownButtonSelected {
  background: #efefef;
}

.dropdownDivButton.dropdownDivButtonSelected > .dropdownChild {
  display: block;
}

.dropdownChild {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 10%);
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
}

.dropdownList:hover {
  background: #f5f5f5;
  border-radius: 4px;
}

.dropdownParent:hover > .dropdownChild {
  display: block;
}

.dropdownChild::before {
  width: 2rem;
  content: '';
  float: right;
  position: absolute;
  right: 100%;
  top: 0;
  height: 100%;
}

.dropdownScroll {
  overflow-y: auto;
  max-height: 305px;
}

/* width */
.dropdownScroll::-webkit-scrollbar {
  width: 10px;
}

/* track */
.dropdownScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c4c4c4;
  border-radius: 5px;
}

/* handle */
.dropdownScroll::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 5px;
}
